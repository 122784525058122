import React, {
  FC,
  useState,
  Fragment,
  useEffect,
  useRef,
  ChangeEvent,
} from "react";
import { Helmet } from "react-helmet-async";
import { RadioGroup } from "@headlessui/react";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import axiosInstance from "api/AxiosInstance";
import InputComponent from "./InputComponent";
import FlyerPagination from "containers/SeriesFlyers/FlyerPagination";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
//import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Link } from "react-router-dom";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import HeadingText1 from "components/HeadingText/HeadingText1";
import { getUserLoginData } from "utils/auth";

// radio button group for Free shipping code start //
const FreeShippingSettings = [
  {
    name: "Fast Delivery",
    item: "FREE",
    value: "CSCLedFreeShip",
    id: "CSCLedFreeShip",
    description: "Shipping with Canada's best carriers as quickly as possible!",
    deliveryMethod: "FreeShipping",
  },
  {
    name: "Your Preferred Shipper",
    item: "TBD",
    value: "CourierFree",
    id: "CourierFree",
    description: " Please provide your courier  name and account number below.",
    deliveryMethod: "FreeShipping",
  },
  {
    name: "Pick Up",
    item: "FREE",
    value: "CSCLedPickUp",
    id: "CSCLedPickUp",
    description: "Even Better! We look forward to seeing you!",
    deliveryMethod: "FreeShipping",
  },
];

// radio button group for Free shipping code start //
const NotFreeShippingSettings = [
  {
    name: "Fast Delivery",
    item: "",
    value: "CSCLedShip",
    id: "CSCLedShip",
    description: "Shipping with Canada's best carriers as quickly as possible!",
    deliveryMethod: "NotFreeShipping",
  },
  {
    name: "Your Preferred Shipper",
    item: "",
    value: "CourierNotFree",
    id: "CourierNotFree",
    description: " Please provide your courier  name and account number below.",
    deliveryMethod: "NotFreeShipping",
  },
  {
    name: "Pick Up",
    item: "FREE",
    value: "CSCLedPickUp",
    id: "CSCLedPickUp",
    description: "Even Better! We look forward to seeing you!",
    deliveryMethod: "NotFreeShipping",
  },
];

export interface addressess {
  id: number;
  companyName: string | null;
  firstNameLastName: string | null;
  lastName: string;
  addressFields: string;
  addressField2: string;
  city: string;
  cityProvincePostalCode: string;
  province: string;
  postalCode: string;
  country: string;
  phoneNumber: string;
  alternativePhoneNumber: string | null;
  emailAddress: string | null;
}

export interface FormData {
  shippingAddressDetails: number;
  shippingCourier: string;
  purchaseOrderNumber: string;
  selectedCustomerId: any;
  //purchaseOrderFile: File | null;
  ifCentralPurchase: boolean;
  shippingPrefrence: any;
  purchaseOrderFileData: any;
  userId: number | null;
}

function classNames(...classes: (string | undefined | null | false)[]): string {
  return classes.filter(Boolean).join(" ");
}

export interface CheckoutProps {
  className?: string;
}

const Checkout: FC<CheckoutProps> = ({ className = "" }) => {
  const user = useAppSelector((state: RootState) => state.auth);
  const userData = getUserLoginData();

  const navigate = useNavigate();
  const [shoppingCartSum, setshoppingCartSum] = useState<boolean>(false);
  //const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<any>(null);

  const [selectedDeliveryMethod, setselectedDeliveryMethod] = useState();
  const [selectNoteChangeValue, setselectNoteChangeValue] = useState("");
  const [selectedCustomerId, setselectedCustomerIdValue] = useState(0);
  const [userCustomerId, setUserCustomerIdValue] = useState(0);
  const [ifPurchaseOrderRequired, setIfPurchaseOrderRequired] = useState(false);
  const [displayDeliveryMethodError, setDisplayDeliveryError] = useState(false);
  const [displayShippingError, setDisplayShippingError] = useState(false);
  const [displayCustomerError, setDisplayCustomerError] = useState(false);
  const [displayPurchaseOrderError, setDisplayPurchaseOrderError] =
    useState(false);
  const [displayPurchaseOrderFileError, setDisplayPurchaseOrderFileError] =
    useState(false);
  const [selectBranchNameValue, setselectBranchNameValue] = useState("");
  const [selectBranchAccountValue, setselectedBranchAccountValue] =
    useState("Add");

  // dialog code //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [file, setFile] = useState<any>();
  // Function to get the initial value from the cookie or default to the first item
  const getInitialSelectedShippingId = () => {
    const savedId = Cookies.get("selectedShippingIdRadio");

    return savedId
      ? parseInt(savedId)
      : currentData.length > 0
      ? currentData[0].id
      : null;
  };
  const [shippingaddressList, setListOfShippingAddress] = useState<
    addressess[]
  >([]);
  //  const [shippingaddressRadio, setradioShippingAddress] = useState(0);
  const [selectedShippingIdRadio, setSelectedradioButtonShippingId] =
    useState(0);

  // pagination code start//
  const [filteredData, setFilteredData] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const handlePageChange = (page: number) => {
    // Select the div you want to scroll to
    const targetDiv = document.getElementById("scroll_div"); // Replace with your div's ID

    if (targetDiv) {
    //  console.log("=== scrooll here");
      // Get the top position of the div relative to the document
      const divTopPosition =
        targetDiv.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: divTopPosition,
        behavior: "smooth", // Smooth scroll
      });
    }
    setCurrentPage(page);
  };

  // Calculate start and end index for current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);

  // Get data for the current page
  const currentData = filteredData.slice(startIndex, endIndex);

  const [customerOptions, setCustomerOptions] = useState([]);
  const [visibleIfCentralPurchase, setVisibleIfCentralPurchase] =
    useState(false);
  // pagination code end //

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // Retrieve the saved shipping method from the cookie on page load
  useEffect(() => {
    fetchData();
    //const userId = Cookies.get("_AuthE1");
    const key = 'cookieselectedDeliveryMethod';
    const cookieUserSpecific =  `${key}_${userData?.userId}}`;     
     const savedShippingMethod = Cookies.get(cookieUserSpecific);   
   
    if (savedShippingMethod) {
      try {
        const parsedMethod = JSON.parse(savedShippingMethod);
        
        if(shoppingCartSum === true){
          // Check if parsed value matches a valid option
          const matchingFreeShippingMethod = FreeShippingSettings.find(
            (setting) => setting.value === parsedMethod.value
          );
          if (matchingFreeShippingMethod) {
            setSelectedShippingMethod(matchingFreeShippingMethod); // Set the selected shipping method state
            setselectedDeliveryMethod(parsedMethod.value);
            const { deliveryMethod, value } = parsedMethod;
            setFormData((prevFormDataF: any) => ({
              ...prevFormDataF,
              [deliveryMethod]: value, // Set purchaseOrderFile to either File object or null
            }));
            formData.shippingPrefrence = parsedMethod.value;
          } else {
            //console.error("No matching shipping method found for cookie value.");
          }
        } else{
          const matchingNotFreeShippingMethod = NotFreeShippingSettings.find(
            (setting) => setting.value === parsedMethod.value
          );
          if (matchingNotFreeShippingMethod) {
            setSelectedShippingMethod(matchingNotFreeShippingMethod); // Set the selected shipping method state            
            setselectedDeliveryMethod(parsedMethod.value);
            const { deliveryMethod, value } = parsedMethod;
            setFormData((prevFormDataNF: any) => ({
              ...prevFormDataNF,
              [deliveryMethod]: value, // Set purchaseOrderFile to either File object or null
            }));
            formData.shippingPrefrence = parsedMethod.value;
          } else {
           // console.error("No matching shipping method found for cookie value.");
          }
        }
      } catch (error) {
        console.error("Failed to parse shipping method from cookie:", error);
      }
    }
  }, [shoppingCartSum]);

  const fetchData = async () => {
    try {
      // First API call to fetch shopping cart details
      const response = await axiosInstance.get(`/Cart/GetShoppingCartTotalSum`);    
      setshoppingCartSum(response.data);

      const responseShipping = await axiosInstance.get(
        `/Cart/GetCartShippingById`
      );
    //  console.log("====>responseShipping", responseShipping);

      // Update the state containing the array with the new data
      setListOfShippingAddress(responseShipping.data);
      setFilteredData(responseShipping.data);

      const responseCartCustomers = await axiosInstance.get(
        `/Cart/GetCartCentralCustomersById`
      );
      setCustomerOptions(responseCartCustomers.data);
      // console.log("===>3", responseCartCustomers.data);

      const responseCartIfPurchaseOrder = await axiosInstance.get(
        `/Cart/GetCartPurchaseOrderNumberById`
      );
      setIfPurchaseOrderRequired(responseCartIfPurchaseOrder.data);

      const responsLastCustomerId = await axiosInstance.get(
        `/Cart/GetCartLastCustomerById`
      );
      setselectedCustomerIdValue(responsLastCustomerId.data.toString());

      const responsGetIfCentralPurchase = await axiosInstance.get(
        `/Cart/GetIfCentralPurchase`
      );
      setVisibleIfCentralPurchase(responsGetIfCentralPurchase.data);

      const responsGetUserCustomerId = await axiosInstance.get(
        `/Cart/GetUserCustomerId`
      );
      setUserCustomerIdValue(responsGetUserCustomerId.data);

      if (selectedShippingIdRadio === 0) {
        const key ='selectedShippingIdRadio';
        const cookieShipAddUserSpecific =  `${key}_${userData?.userId}}`;  
        const savedShippingId = Cookies.get(cookieShipAddUserSpecific);
        if (savedShippingId !== undefined) {
          setSelectedradioButtonShippingId(parseInt(savedShippingId));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //-------- upload file code start ------------//
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Function to handle file selection and update FormData
  const handleFileChange = (file: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      purchaseOrderFile: file, // Set purchaseOrderFile to either File object or null
    }));
    // console.log("========file",file);
    setFile(file);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form data code
  const [formData, setFormData] = useState({
    shippingAddressDetails: 0,
    shippingCourier: "",
    purchaseOrderNumber: "",
    selectedCustomerId: 0,
    purchaseOrderFile: null,
    ifCentralPurchase: false,
    shippingPrefrence: "",
    purchaseOrderFileData: "",
    userId: 0,
  });
  const [errors, setErrors] = useState({
    FormData,
  });

  // Form data code
  const [branchformData, setBraanchFormData] = useState({
    type: "",
    details: "",
    status: "",
    archive: false,
    IsDeleted: false,
    userId: 0,
  });



  // Function to handle onChange event and update selectedShippingMethod state
  const handleDeliveryShippingMethodChange = (e: any) => {

    setSelectedShippingMethod(e);   
   // console.log("=>cookieDeliveryMethod",cookieDeliveryMethod);
    setselectedDeliveryMethod(e.value);
    const { deliveryMethod, value } = e;
    setFormData((prevFormData2: any) => ({
      ...prevFormData2,
      [deliveryMethod]: value, // Set purchaseOrderFile to either File object or null
    }));
    //  setFormData({ ...formData, [deliveryMethod]: value });
    formData.shippingPrefrence = e.value;
    const key ='cookieselectedDeliveryMethod';
    const cookieUserSpecific =  `${key}_${userData?.userId}}`;
   
   // Save the shipping details as a cookie
    Cookies.set(cookieUserSpecific,
    JSON.stringify(e),
    {expires: 7, secure: true, sameSite: 'Strict' }); // Expires in 7 day
   // console.log(`Cookie set: ${cookieUserSpecific} = ${JSON.stringify(e)}`);
   
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const handleNoteChange = (e: any) => {
    // console.log("=====>handleNoteChange", e.target.value);
    setselectNoteChangeValue(e.target.value);
  };

  const handleSelectChangeCustomerId = (event: any) => {
    setselectedCustomerIdValue(event.target.value);
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const handleSelectedShippingAddress = (e: any) => {
    setSelectedradioButtonShippingId(e);
    let selectedShippingIdRadio = e;
    const key ='selectedShippingIdRadio';
    const cookieShipAddUserSpecific =  `${key}_${userData?.userId}}`;    
    if (selectedShippingIdRadio !== undefined) {
      Cookies.set(
        cookieShipAddUserSpecific,
        selectedShippingIdRadio.toString(),
        {expires: 7, secure: true, sameSite: 'Strict' }
      ); // Expires in 7 days
    }
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (selectedDeliveryMethod === undefined || selectedDeliveryMethod === "") {
      setDisplayDeliveryError(true);
      newErrors.DeliveryMethod = "true";
    } else {
      setDisplayDeliveryError(false);
    }
    if (selectedDeliveryMethod === "CSCLedPickUp") {
      formData.shippingAddressDetails = 0;
      setSelectedShippingMethod(null);
      // newErrors.shippingAddress = "true";
    }

    if (selectedShippingIdRadio === 0 && selectedDeliveryMethod !== "CSCLedPickUp") {
      setDisplayShippingError(true);
      newErrors.ShippingIdRadio = "true";
    } else {
      setDisplayShippingError(false);
    }

    if (selectedCustomerId === 0) {
      setDisplayCustomerError(true);
      newErrors.SelectedCustomerId = "true";
    } else {
      setDisplayCustomerError(false);
    }
    if (
      formData.purchaseOrderNumber === "" &&
      ifPurchaseOrderRequired !== false
    ) {
      setDisplayPurchaseOrderError(true);
      newErrors.PurchaseOrderRequired = "true";
    } else {
      setDisplayPurchaseOrderError(false);
    }
    if (
      (formData.purchaseOrderFile === null || formData.purchaseOrderFile === undefined) &&
      ifPurchaseOrderRequired !== false
    ) {
      setDisplayPurchaseOrderFileError(true);
      newErrors.PurchaseOrderFileRequired = "true";
    } else {
      setDisplayPurchaseOrderFileError(false);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length !== 0 ? true : false;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Call the onSubmit function from props with the form data
    handleFormSubmit(formData);
  };

  const handleFormSubmit = async (data: any) => {
    formData.shippingCourier = selectNoteChangeValue;
    if (visibleIfCentralPurchase === true) {
      formData.selectedCustomerId = selectedCustomerId;
    } else {
      formData.selectedCustomerId = userCustomerId;
    }

    // Check if selectedShippingIdRadio is not undefined before assigning it
    if (selectedShippingIdRadio !== undefined) {
      formData.shippingAddressDetails = selectedShippingIdRadio;
    } else {
      // Provide a default value if selectedShippingIdRadio is undefined
      formData.shippingAddressDetails = 0; // or any other default value
    }
    formData.ifCentralPurchase = visibleIfCentralPurchase;

    formData.userId = user.userData?.userId ?? 0;
    var isValid = validateForm();   
   
    if (formData.purchaseOrderFile !== null && formData.purchaseOrderFile !== undefined && isValid === false) {
      const formDataFile = new FormData();
      formDataFile.append("model", file); // 'model' should match the parameter name in your API method

    //  console.log("formDataFile", formDataFile);
      const responseFileUpload = await axiosInstance.post(
        "/Cart/SavePurchaseFileOnAWSById",
        formDataFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      formData.purchaseOrderFileData = responseFileUpload.data;
      // console.log(
      //   "responseFileUpload File uploaded successfully:",
      //   responseFileUpload.data
      // );
    }

    if (isValid === false) {
      console.log("Check-Out Page formdata and going on Review order page=>",formData);
      navigate("/Cart/review-order", { state: { formData } });
      //console.log("=error", Object.keys(errors).length);
    }
  };

  const handleBranchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // validateForm();

    // Call the onSubmit function from props with the form data
    handleBranchFormSubmit(formData);
  };

  const handleBranchFormSubmit = async (data: any) => {
    branchformData.type = selectBranchAccountValue;
    branchformData.details = selectBranchNameValue;
    branchformData.userId = user.userData?.userId ?? 0;
    branchformData.status = "New";
    branchformData.archive = false;
    branchformData.IsDeleted = false;
    setBraanchFormData(branchformData);
    await axiosInstance.post(`/Cart/saveSFRequestData`, branchformData);
    closeModalMoreFilter(); // Close the modal after submitting
  };

  // State to hold the search text
  const [searchText, setSearchText] = useState("");

  // Method to handle changes in the search input
  const handleFilterChange = (name: any, value: any) => {
    if (name === "searchText") {
      setSearchText(value);
      const filteredItems = shippingaddressList.filter((item: any) => {
        return item.companyName.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredData(filteredItems);
    }
  };

  // Function to handle onChange event and update setselectBranchNameValue state
  const handleBranchName = (e: any) => {
   // console.log("=====>handleBranchName", e.target.value);
    setselectBranchNameValue(e.target.value);
  };

  const handleBranchtomyaccount = (event: any) => {
   // console.log("==>", event);
    setselectedBranchAccountValue(event.target.value);
  };
 
  const handleManageShippingLink = () => {
    const redirect = 'checkout';
    navigate("/shipping-address", { state: { redirect } }); 
  }
  const renderSearch = () => {
    return (
      <form className="relative w-full" onSubmit={(e) => e.preventDefault()}>
        <label
          htmlFor="search-input"
          className="text-neutral-500 dark:text-neutral-300"
        >
          <Input
            className="shadow-lg border-0 dark:border"
            id="search-input"
            type="search"
            placeholder="search"
            sizeClass="pl-14 py-2 pr-5 md:pl-16"
            rounded="rounded-full"
            autoComplete="Off"
            onChange={(e) => handleFilterChange("searchText", e.target.value)}
          />
          <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
            <svg
              className="h-5 w-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22L20 20"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </label>
      </form>
    );
  };

  //-------- upload file code end ------------//
  return (
    <div>
      <div className={`nc-Checkout ${className}`} data-nc-id="Checkout">
        <Helmet>
          <title>Order Details | CSC LED</title>
        </Helmet>
        <div className="container py-16 lg:pt-12">
          <form onSubmit={handleSubmit}>
            <div className="w-full">
              <HeadingText1 className="font-poppins mb-12">
                {" "}
                Order Details
              </HeadingText1>
              {/* <h2 className="text-3xl md:text-4xl font-semibold mb-12">
                Order Details
              </h2> */}
              <div className="border p-3 border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
                {shoppingCartSum === true ? (
                  <div className="flex items-center p-3">
                    <i className="text-4xl object-contain object-center mr-2 fi fi-rr-piggy-bank"></i>
                    <p className="text-black">
                      Your order qualifies for free shipping!
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center p-3">
                    <i className="text-4xl object-contain object-center mr-2 fi fi-rr-piggy-bank"></i>
                    <p className="text-black">
                      We can provide <b>free nationwide shipping</b> for orders
                      with a <b>minimum subtotal of $250</b> and a shipping
                      address to a partnered distributor's location.
                    </p>
                  </div>
                )}
              </div>
              <div className="border my-6 border-slate-200 dark:border-slate-700 rounded-xl ">
                <p className="p-6 text-xl font-medium text-csccolor">
                  Select a Delivery Method:
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 p-6 block">
                  {/*  Free Shipping Code  */}
                  {shoppingCartSum === true ? (
                    <div className="flex w-full">
                      <RadioGroup
                        value={selectedShippingMethod}
                        onChange={handleDeliveryShippingMethodChange}
                      >
                        <RadioGroup.Label className="sr-only">
                          Privacy setting
                        </RadioGroup.Label>
                        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 rounded-md bg-white">
                          {FreeShippingSettings.map((setting, settingIdx) => (
                            <RadioGroup.Option
                              key={setting.name}
                              value={setting}
                              className={({ checked }) =>
                                classNames(
                                  settingIdx === 0
                                    ? "rounded-md"
                                    : "rounded-md",
                                  settingIdx === FreeShippingSettings.length - 1
                                    ? "rounded-md"
                                    : "",
                                  checked
                                    ? "z-10 border-indigo-200 bg-indigo-50"
                                    : "border-slate-200",
                                  "relative flex items-baseline cursor-pointer border p-6 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <span
                                    className={classNames(
                                      checked
                                        ? "bg-indigo-600 border-transparent"
                                        : "bg-white border-slate-300",
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-600"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span>
                                  <span className="ml-3 flex flex-col">
                                    <div className="flex w-full justify-between">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-900"
                                            : "text-gray-900",
                                          "block text-base font-medium"
                                        )}
                                      >
                                        {setting.name}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="span"
                                        className={classNames(
                                          setting.item === "FREE"
                                            ? "text-csccolor-green"
                                            : "text-gray-900",
                                          "block text-base font-medium"
                                        )}
                                      >
                                        {setting.item}
                                      </RadioGroup.Description>
                                    </div>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm mt-3"
                                      )}
                                    >
                                      {setting.description}
                                    </RadioGroup.Description>
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  ) : (
                    //   Not Free Shipping Code  }
                    <div className="flex w-full">
                      <RadioGroup
                        value={selectedShippingMethod}
                        onChange={handleDeliveryShippingMethodChange}
                      >
                        <RadioGroup.Label className="sr-only">
                          Privacy setting
                        </RadioGroup.Label>
                        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 rounded-md bg-white">
                          {NotFreeShippingSettings.map(
                            (setting, settingIdx) => (
                              <RadioGroup.Option
                                key={setting.name}
                                value={setting}
                                className={({ checked }) =>
                                  classNames(
                                    settingIdx === 0
                                      ? "rounded-md"
                                      : "rounded-md",
                                    settingIdx ===
                                      NotFreeShippingSettings.length - 1
                                      ? "rounded-md"
                                      : "",
                                    checked
                                      ? "z-10 border-indigo-200 bg-indigo-50"
                                      : "border-slate-200",
                                    "relative flex items-baseline cursor-pointer border p-6 focus:outline-none"
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        checked
                                          ? "bg-indigo-600 border-transparent"
                                          : "bg-white border-slate-300",
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-600"
                                          : "",
                                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <span className="ml-3 flex flex-col">
                                      <div className="flex w-full justify-between">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(
                                            checked
                                              ? "text-indigo-900"
                                              : "text-gray-900",
                                            "block text-base font-medium"
                                          )}
                                        >
                                          {setting.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(
                                            setting.item === "FREE"
                                              ? "text-csccolor-green"
                                              : "text-gray-900",
                                            "block text-base font-medium"
                                          )}
                                        >
                                          {setting.item}
                                        </RadioGroup.Description>
                                      </div>
                                      <RadioGroup.Description
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-700"
                                            : "text-black",
                                          "block text-sm mt-3"
                                        )}
                                      >
                                        {setting.description}
                                      </RadioGroup.Description>
                                    </span>
                                  </>
                                )}
                              </RadioGroup.Option>
                            )
                          )}
                        </div>
                      </RadioGroup>
                    </div>
                  )}
                  <div className="form-group">
                    <div className="w-full mt-6">
                      <label className="block text-black text-base mb-2">
                        Anything else we should know about for this order?
                      </label>
                      <textarea
                        id="shippingCourier"
                        name="shippingCourier"
                        className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        maxLength={300}
                        rows={4}
                        cols={80}
                        spellCheck={false}
                        value={selectNoteChangeValue}
                        onChange={handleNoteChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {visibleIfCentralPurchase === true ? (
              <div className="border w-full my-6 border-slate-200 dark:border-slate-700 rounded-xl ">
                <p className="p-6 text-xl font-medium text-csccolor">
                  Who should the invoice go to?
                </p>
                <div className="border-t w-full border-slate-200 dark:border-slate-700 p-6 block">
                  <div className="flex-grow w-full space-y-6">
                    <div>
                      <Label>Your locations</Label>
                      <Select
                        className="mt-1.5"
                        value={selectedCustomerId}
                        onChange={handleSelectChangeCustomerId}
                      >
                        <option value="--Select Branch--">
                          --Select Branch--
                        </option>
                        {customerOptions.map((option: any, index: any) => (
                          <option
                            key={index}
                            className="capitalize"
                            value={option.id}
                          >
                            {option.customer1}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="mt-2">
                      <div
                        className="text-center inline-flex items-center justify-center rounded-full transition-colors text-sm cursor-pointer sm:text-base font-medium py-2.5  text-slate-700 hover:bg-slate-200  bg-slate-100 focus:outline-none focus:ring-2 px-4 focus:ring-offset-2 focus:ring-primary-600 dark:focus:ring-offset-0"
                        onClick={openModalMoreFilter}
                      >
                        I need to add or remove a branch from my account
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            ) : (
              ""
            )}

            {selectedDeliveryMethod !== "CSCLedPickUp" ? (
              <div
                id="scroll_div"
                className="border w-full my-6 border-slate-200 dark:border-slate-700 rounded-xl "
              >
                <p className="p-6 text-csccolor text-xl font-medium">
                  Where is this order being shipped to?
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 p-6 block">
                  <div className="w-full">
                    <div className=" flex flex-col items-start md:items-center justify-between md:flex-row md:space-x-2 md:space-y-0">
                      <div className="flex flex-col sm:flex-row w-full md:w-100 sm:w-full">
                      <Link to={'/add-shipping-address/checkout'}>
                        <ButtonPrimary
                          className="capitalize sm:!px-7 shadow-none"                          
                        >
                          add shipping address
                        </ButtonPrimary>
                        </Link>
                        {/* <ButtonPrimary
                          className="capitalize sm:!px-7 shadow-none"
                          href="/add-shipping-address"                          
                        >
                          add shipping address
                        </ButtonPrimary> */}
                        <ButtonPrimary
                          className="capitalize mt-3 sm:mt-0 sm:ml-3"
                          //href="/shipping-address"
                          onClick={() =>handleManageShippingLink()}
                        >
                          manage shipping addresses
                        </ButtonPrimary>
                      </div>
                      <div className="flex md:justify-end justify-start w-full md:w-100 sm:w-full">
                        <div className="mt-4 md:ml-2 ml-0  w-full max-w-full md:max-w-lg lg:max-w-xs md:mt-0 sm:flex-auto">
                          <div>{renderSearch()}</div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full mt-6">
                      <RadioGroup
                        value={selectedShippingIdRadio}
                        onChange={handleSelectedShippingAddress}
                      >
                        <RadioGroup.Label className="sr-only">
                          Privacy setting
                        </RadioGroup.Label>
                        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 lg:grid-cols-4 rounded-md bg-white">
                          {currentData.map((item: any, addressesIdx: any) => (
                            <RadioGroup.Option
                              key={item.id}
                              value={item.id}
                              className={({ checked }) =>
                                classNames(
                                  addressesIdx === 0
                                    ? "rounded-md"
                                    : "rounded-md",
                                  addressesIdx === currentData.length - 1
                                    ? "rounded-md"
                                    : "",
                                  checked
                                    ? "z-10 border-indigo-200 bg-indigo-50"
                                    : "border-slate-200",
                                  "relative flex items-baseline cursor-pointer border p-6 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <span
                                    className={classNames(
                                      checked
                                        ? "bg-indigo-600 border-transparent"
                                        : "bg-white border-black",
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-600"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span>
                                  <span className="ml-3 flex flex-col">
                                    <RadioGroup.Label
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-900"
                                          : "text-gray-900",
                                        "block text-base mb-3 font-medium"
                                      )}
                                    >
                                      {item.companyName}
                                    </RadioGroup.Label>

                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      {item.companyName}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      {item.addressFields}
                                    </RadioGroup.Description>

                                    {item.addressField2 != null ? (
                                      <RadioGroup.Description
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-700"
                                            : "text-black",
                                          "block text-sm"
                                        )}
                                      >
                                        {item.addressField2}
                                      </RadioGroup.Description>
                                    ) : (
                                      ""
                                    )}

                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      {item.cityProvincePostalCode}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      {item.city}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      {item.country}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      {item.firstNameLastName}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      p: {item.phoneNumber}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-700"
                                          : "text-black",
                                        "block text-sm"
                                      )}
                                    >
                                      e: {item.emailAddress}
                                    </RadioGroup.Description>
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                    {/* Pagination */}
                    <div className="flex w-full justify-between mt-6 items-center">
                      <div className="w-full">
                        {/* Your content here */}
                        <FlyerPagination
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          totalItems={filteredData?.length as number}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="border w-full my-6 border-slate-200 dark:border-slate-700 rounded-xl ">
              <p className="p-6 text-csccolor text-xl font-medium">
                Upload a Purchase Order
              </p>
              <div className="border-t w-full border-slate-200 dark:border-slate-700 p-6 block">
                <div className="flex-grow w-full space-y-6">
                  <div>
                    <Label>Your purchase order number</Label>
                    <Input
                      className="mt-1.5"
                      type="text"
                      value={formData.purchaseOrderNumber}
                      name="purchaseOrderNumber"
                      id="purchaseOrderNumber"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <InputComponent
                      placeholder=""
                      type={"file"}
                      labelTextName="Upload your purchase order file"
                      onChange={(e) =>
                        handleFileChange(
                          e.target.files ? e.target.files[0] : null
                        )
                      }
                      autoComplete="Off"
                    />
                  </div>
                </div>
              </div>
            </div>

            {displayDeliveryMethodError === true ? (
              <div className="mt-2">
                <p className="flex items-center rounded-xl text-black font-semibold bg-amber-500 px-6 py-6">
                  <i className="text-4xl object-contain object-center mr-2 fi fi-sr-triangle-warning"></i>
                  A delivery method must be selected above in order to proceed
                  with your order.
                </p>
              </div>
            ) : (
              ""
            )}

            {displayShippingError === true ? (
              <div className="mt-2">
                <p className="flex items-center rounded-xl text-black font-semibold bg-amber-500 px-6 py-6">
                  <i className="text-4xl object-contain object-center mr-2 fi fi-sr-triangle-warning"></i>
                  You must select a shipping address for your order.
                </p>
              </div>
            ) : (
              ""
            )}

            {displayCustomerError === true ? (
              <div className="mt-2">
                <p className="flex items-center rounded-xl text-black font-semibold bg-amber-500 px-6 py-6">
                  <i className="text-4xl object-contain object-center mr-2 fi fi-sr-triangle-warning"></i>
                  Order's Branch is a required field in order to proceed with
                  your order.
                </p>
              </div>
            ) : (
              ""
            )}

            {displayPurchaseOrderError === true ? (
              <div className="mt-2">
                <p className="flex items-center rounded-xl text-black font-semibold bg-amber-500 px-6 py-6">
                  <i className="text-4xl object-contain object-center mr-2 fi fi-sr-triangle-warning"></i>
                  A purchase order number is required in order to proceed with
                  your order.
                </p>
              </div>
            ) : (
              ""
            )}

            {displayPurchaseOrderFileError === true ? (
              <div className="mt-2">
                <p className="flex items-center rounded-xl text-black font-semibold bg-amber-500 px-6 py-6">
                  <i className="text-4xl object-contain object-center mr-2 fi fi-sr-triangle-warning"></i>
                  A purchase order file is required in order to proceed with
                  your order.
                </p>
              </div>
            ) : (
              ""
            )}

            <div className="flex flex-col sm:flex-row w-full md:w-100 sm:w-full mt-6">
              <ButtonCSCLed type="submit" className="capitalize">
                review your order
              </ButtonCSCLed>

              <ButtonThird
                href={`/Cart/index`}
                className="block my-1 sm:text-sm text-s capitalize sm:px-6 px-2 sm:py-3.5 py-2 text-center"
              >
                back to cart
              </ButtonThird>
            </div>
          </form>
        </div>
      </div>
      <Transition appear show={isOpenMoreFilter} as={Fragment}>
        <Dialog
          as="div"
          className="fixed flex justify-center align-middle items-center inset-0 z-50 overflow-y-auto"
          onClose={closeModalMoreFilter}
        >
          <div className="text-center flex justify-center align-middle">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              className="inline-block w-full max-w-4xl"
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg capitalize font-medium leading-6 text-gray-900"
                  >
                    branch update request form
                  </Dialog.Title>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModalMoreFilter} />
                  </span>
                </div>
                <form onSubmit={handleBranchSubmit}>
                  <div className="flex-grow p-8 pt-0 overflow-y-auto">
                    <div className="">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="my-6">
                        <Label>I need to:</Label>
                        <Select
                          className="mt-1.5"
                          onChange={handleBranchtomyaccount}
                          value={selectBranchAccountValue}
                        >
                          <option value="Add" selected>
                            Add a branch to my account
                          </option>
                          <option value="Remove">
                            Remove a branch from my account
                          </option>
                        </Select>
                      </div>

                      <div className="my-6">
                        <Label>Branch Name And Location:</Label>
                        <Input
                          className="mt-1.5"
                          type="text"
                          name="branchName"
                          id="branchName"
                          value={selectBranchNameValue}
                          onChange={handleBranchName}
                        />
                      </div>
                      <p className="text-black">
                        This request may take us up to two business days to
                        complete. In the meantime, please go ahead and submit
                        your order with any branch, and mention the correct
                        branch name in your order details with a purchase order.
                      </p>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center">
                    {/* <ButtonSecondary
                                  onClick={() => {
                                    closeModalMoreFilter();
                                  }}
                                  sizeClass="py-2.5 px-5"
                                >
                                  Close
                                </ButtonSecondary> */}
                    <ButtonPrimary sizeClass="py-2.5 px-5" type="submit">
                      Send
                    </ButtonPrimary>

                    <ButtonSecondary
                      onClick={() => {
                        closeModalMoreFilter();
                      }}
                      className="ml-2"
                      type="button"
                    >
                      Cancel
                    </ButtonSecondary>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Checkout;
